.blobs-background {
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  height: 100vh;
  width: 100vw;
}

/* .blob-1 {
  position: fixed;
  top: "45vh";
  left: "45vw";
  z-index: -1;
  width: "100px";
  height: "100px";
}

.blob-2 {
  position: fixed;
  top: "45vh";
  left: "45vw";
  z-index: -1;
  width: "10vw";
  height: "10vw";
}

.blob-3 {
  position: fixed;
  top: "45vh";
  left: "45vw";
  z-index: -1;
  width: "10vw";
  height: "10vw";
} */
