/* body {
    font-family: system-ui;
  } */
  
  .timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  
  .title > h2,
  .title > h1 {
    margin: 0;
  }
  
  .title > h2 {
    opacity: 50%;
  }
  
  .events {
    position: relative;
    
    display: grid;
    grid-template-columns: 16px 1fr;
    row-gap: 16px;
    
    padding-top: 32px;
    width: fit-content;
  }
  
  .event {
    display: grid;
    grid-template-columns: 16px 1fr;
    column-gap: 16px;
    grid-column: 1 / 3;
  }
  
  .date > h2,
  .description > p {
    margin: 0;
  }
  
  .knob {
    grid-column: 1 / 2;
    align-self: center;
    width: 100%;
    aspect-ratio: 1 / 1; 
    z-index: 1;
    
    background: #4c4c4c;
    border-radius: 50%;
  }
  
  .date,
  .description {
    grid-column: 2 / 3;
    align-self: center;
  }
  
  .description span {
    opacity: .5;
  }
  
  .line {
    position: absolute;
    
    grid-column: 1 / 2;
    justify-self: center;
    
    height: 100%;
    width: 4px;
    z-index: 0;
    
    background-color: rgba(0, 0, 0, .1);
  }