section {
  position: fixed;
  width: 100vw;
  height: 100vh;
  /* position: relative; */
  bottom: 0;
  left: 0;
  /* background: #90e0ff; */
  background-color: #90e0ff; /* For browsers that do not support gradients */
  background-image: linear-gradient(#ffffff 30%, #90e0ff, #3acbdd);
  overflow: hidden;
  z-index: -1;
}

section .wave {
  /* position: absolute; */
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(wave.png);
  background-size: 1000px 100px;
  /* transform-origin:center bottom;
    animation: wave-animation 1s infinite linear; */
}

section .wave.wave1 {
  /* background-position: 0 0; */
  animation: animate 30s linear infinite;
  z-index: 5;
  opacity: 1;
  animation-delay: 0s;
  bottom: 0;
}
@keyframes animate {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}

section .wave.wave2 {
  /* background-position: 0 0; */
  animation: animate2 15s linear infinite;
  z-index: 4;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;
}
@keyframes animate2 {
  0% {
    background-position-x: 0;
  }
  100% {
    background-position-x: -1000px;
  }
}

section .wave.wave3 {
  /* background-position: 0 0; */
  animation: animate 30s linear infinite;
  z-index: 3;
  opacity: 0.2;
  animation-delay: -2s;
  bottom: 15px;
}

section .wave.wave4 {
  /* background-position: 0 0; */
  animation: animate2 5s linear infinite;
  z-index: 2;
  opacity: 0.7;
  animation-delay: -5s;
  bottom: 20px;
}
