.an-sidebar {
  /* background-color: #060b26;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center; */
  /* padding-top: 1rem; */
}
/* Show Button */
.an-menu-bar {
  margin-left: 0;
  /* margin-top: 1rem; */
  padding: 0.75rem 0.5rem 0.5rem 0.8rem;
  height: 100%;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
  font-size: 1rem;
  background: #060b26;
  color: #fff;
  z-index: 10000;
  position: fixed;
  /* top: 0; */
  top: 70px;
  left: 0;
}
.an-menu-bar.active {
  left: 100%;
}
/* Hide Button */
.an-sidebar-nav-link {
  margin-left: 0;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  padding: 0.5rem 0.5rem 0.5rem 0.8rem;
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  font-size: 1rem;
  background: #060b26;
  color: #fff;
  z-index: 10000;
  position: absolute;
  /* position: fixed; */
  /* top: 0; */
  top: 0px;
  right: 0;
}

.an-sidebar-nav {
  padding-top: 1.5rem;
  background-color: #ffffff;
  width: 260px;
  height: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 70px;
  /* top: 0; */
  left: -100%;
  transition: 850ms;
  z-index: 10000;
  overflow-x: scroll;
}

.an-sidebar-nav.active {
  left: 0;
  transition: 350ms;
}

.an-nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 8px 0 8px 16px;
  list-style: none;
  height: 60px;
}

.an-nav-tex a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 18px;
  width: 95%;
  height: 100%;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
}

.an-nav-text a:hover {
  background-color: #0a0a0a;
  color: #f5f5f5;
}

.an-sidebar-nav-list {
  padding-left: 5px;
}
.an-sidebar-item {
  width: 100%;
}

.an-sidebar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}
