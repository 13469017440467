body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.p-dialog-bottom .an-dialog.p-dialog{
  /* max-height: 80vh; */
  width: 100vw;
  height: 90vh;
  margin: 0 !important;
}

.an-dialog.p-dialog{
  max-height: 100vh;
  width: 450px;
  height: 100vh;
  margin: 0 !important;
} 

.an-dialog.p-dialog .p-dialog-content{
  padding: 0 1.5rem;
}

.p-datatable .p-datatable-tbody > tr {
  height: fit-content !important;
}

.p-datatable-scrollable .p-datatable-thead>tr>th, .p-datatable-scrollable .p-datatable-tbody>tr>td, .p-datatable-scrollable .p-datatable-tfoot>tr>td {
  /* flex: unset !important; */
}

.main_container {
  transition: margin-left 600ms ease-out;
}