.App {
  margin: 0;
  padding: 0;
}
.body_1{
  background: #2980B9;  /* fallback for old browsers */
  /*background: -webkit-linear-gradient(to top, #FFFFFF, #6DD5FA, #2980B9);  /* Chrome 10-25, Safari 5.1-6 */
  /*background: linear-gradient(to top, #FFFFFF, #6DD5FA, #2980B9); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  background: radial-gradient(66.32% 66.32% at 54.13% 113.95%,rgba(111, 236, 255, 0.2) 0,rgba(151, 245, 255, 0) 100%),linear-gradient(211.99deg,rgba(65,157,241,.2) -4.17%,rgba(45,143,234,0) 68.7%),radial-gradient(100% 100% at 28.65% 0,rgba(0, 229, 255, 0.25) 0,rgba(250,247,254,0) 100%);
  
}
.body_2{
  /* background-image: url("https://i2.wp.com/background4free.com/download/blue_aqua_purple_32809935.jpg"); */
  /* background-image: url("https://i2.wp.com/background4free.com/download/sky_daytime_blue_389737936.jpg"); */
background:#FFFFFF;/* #B2FEFA;  /* fallback for old browsers */
background: -webkit-linear-gradient(to top, #0ED2F7, #B2FEFA, #FFFFFF, #FFFFFF);  /* Chrome 10-25, Safari 5.1-6 */
background: linear-gradient(to top, #0ED2F7, #B2FEFA, #FFFFFF, #FFFFFF); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
background-repeat: 'no-repeat';
  background-size: 100% 100%;
  min-height: 95vh;
}

.body_3{
  background: radial-gradient(66.32% 66.32% at 54.13% 113.95%,rgba(111, 236, 255, 0.2) 0,rgba(151, 245, 255, 0) 100%),linear-gradient(211.99deg,rgba(65,157,241,.2) -4.17%,rgba(45,143,234,0) 68.7%),radial-gradient(100% 100% at 28.65% 0,rgba(0, 229, 255, 0.25) 0,rgba(250,247,254,0) 100%);
  background-size: 100% 100%;
  min-height: 95vh;
}


::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  /* background-color: #f5f5f5; */

}

/* Track */
::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 5px grey; 
  border-radius: 10px; */
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(146, 146, 146, 0.5); 
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(126, 126, 126, 0.8); 
}