
.line-height-15 {
    line-height: 1.5;
}
.color-code-06 {
    color: rgba(0, 0, 0, 0.6);
    color: var(--secondary-text);
}
.body-2-text {
    font-size: 14px;
    font-size: var(--body2-fontSize);
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.25px;
}
.color-code-087 {
    color: rgba(0, 0, 0, 0.87);
    color: var(--primary-text);
}
.subtitle-2-text {
    font-size: 14px;
    font-size: var(--body2-fontSize);
    font-stretch: normal;
    font-style: normal;
    letter-spacing: 0.1px;
}
.mediumFont {
    font-weight: 500 !important;
}