.modified .custom-file-label {
    top: 24px;
    margin: 5px 12px;
    overflow: hidden;
    z-index: 0;
}

.modified .custom-file-label ::after {
    z-index: 0;
}

.modified .custom-file-input{
    z-index: -1;
}


.modified .an-file-download{
    overflow: hidden;
    font-size: smaller;
    z-index: 1000;
}

.modified .an-file-download .file-input-pad{
    position: absolute;
    top: 70px;
    left: 30px;
}

.modified .an-file-download-name{
    display: inline-flex;
    width: 163px;
    height: 25px;
    overflow: hidden;
    /* box-shadow: inset -6px 0 #9d9d9d87; */
    /* box-shadow: inset -6px 0 linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0.68) 100%); */
}

.modified .an-file-download-name::after{
    background: linear-gradient(270deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0.68) 100%);
}

.modified .pointer{
    cursor: pointer;
}